import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SitesTable from './SitesTable';
import Filters from './Filters/Filters';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { ISiteMngData } from './types';
import { Roles, getSMAGlobalSetting, verifyJwtToken } from './helpers';
import SimpleBackdrop from './SimpleBackdrop';
import { IInitialFilterRes, ISmaLanguage, IStudyItem } from './types';
import Legend from './Legend';
import WarningIcon from '@mui/icons-material/Warning';
import {
  setFieldOptions,
  setEprFieldOptions,
  setRefreshSiteConfig,
  setSiteStatusOptions,
  setUserName,
} from '../../features/smaSlice';

const SiteManagement = () => {
  const [data, setData] = useState<ISiteMngData[]>([]);
  const [cloneData, setCloneData] = useState<ISiteMngData[]>([]);
  const [eprOptions, setEprOptions] = useState<IStudyItem[]>([]);
  const [langOptions, setLangOptions] = useState<ISmaLanguage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedEprName, setSelectedEprName] = useState<string>('');
  const [role, setRole] = useState('');
  const [authStatus, setAuthStatus] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const getInitialData = async () => {
      try {
        setIsLoading(true);
        const initialData: IInitialFilterRes = await getSMAGlobalSetting();
        const eprList = initialData.eprStudyList;
        const taList = initialData.taStudyList;
        const langList = initialData.smaLanguageList;
        const siteStatuses = initialData.siteStatuses;
        const fields = initialData.fields;
        const eprFields = initialData.eprFields;
        let siteStatusesNames: string[] = [];
        if (Array.isArray(siteStatuses)) {
          siteStatusesNames = siteStatuses.map((i) => i.name);
        }
        const studyList = [...taList, ...eprList];
        setEprOptions(studyList);
        setLangOptions(langList);
        setIsLoading(false);
        dispatch(setFieldOptions(fields));
        dispatch(setEprFieldOptions(eprFields));
        dispatch(
          setRefreshSiteConfig({
            optedIn: initialData.optedIn || false,
            radius: initialData.radius || 0,
            //default priority is blank
            priority: initialData.priority || '',
            threshold: initialData.threshold || 0,
            warmTransferNumber: initialData.warmTransferNumber || '',
            optInDateTime: initialData.optInDateTime || new Date().toISOString(),
            isDefaultVirtualSite: initialData.isDefaultVirtualSite || false,
            isHybridSite: initialData.isHybridSite || false,
            kiElementsEnabled: initialData.kiElementsEnabled || false,
            siteDirectedSchedule: initialData.siteDirectedSchedule || false,
          })
        );
        dispatch(setSiteStatusOptions(siteStatusesNames));
      } catch (_error) {
        setIsLoading(false);
      }
    };
    getInitialData();
  }, []);

  useEffect(() => {
    const callVerify = async (smaToken: string) => {
      if (smaToken) {
        if (!role) {
          const res = await verifyJwtToken(smaToken);
          if (res?.isValid && res?.payload?.roles) {
            const userRole = res?.payload?.roles;
            const userName = res?.payload?.userName;
            const allowedRoles = Object.values(Roles);
            let isAllowed = false;
            allowedRoles.forEach((allowedRole) => {
              if (userRole.includes(allowedRole)) {
                isAllowed = true;
              }
            });
            if (isAllowed) {
              setRole(res.payload.roles);
              setAuthStatus('passed');
              dispatch(setUserName(userName));
            } else {
              setAuthStatus('failed');
            }
          } else {
            setAuthStatus('failed');
          }
        }
      }
    };
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const userinfoParam = params.get('userinfo');
    if (userinfoParam) {
      const smaToken = userinfoParam;
      if (smaToken && typeof smaToken === 'string') {
        sessionStorage.setItem('sma_token', smaToken);
        callVerify(smaToken);
      }
    }
  }, []);

  useEffect(() => {
    const checkAuth = setTimeout(() => {
      if (!role) {
        setAuthStatus('failed');
      }
    }, 30 * 1000);
    return () => {
      clearTimeout(checkAuth);
    };
  }, [role]);

  if (authStatus === 'failed') {
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        paddingTop={'2rem'}
      >
        <WarningIcon sx={{ fontSize: 48, color: 'error.main' }} />
        <Typography variant="h6" color="error" paddingTop={1}>
          Authentication Failed / You are not authorized to view this page
        </Typography>
      </Box>
    );
  } else if (!role) {
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        paddingTop={'2rem'}
      >
        <CircularProgress />
        <Typography>Authenticating</Typography>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          marginBlock: '2rem',
          marginInline: '5rem',
        }}
      >
        <SimpleBackdrop isLoading={isLoading} />
        <Box marginTop={'1rem'}>
          <Typography variant={'h3'}>Site Management</Typography>
        </Box>
        <Box marginTop={'1rem'}>
          <Filters
            data={data}
            setData={setData}
            eprOptions={eprOptions}
            langOptions={langOptions}
            setIsLoading={setIsLoading}
            setSelectedEprName={setSelectedEprName}
            setCloneData={setCloneData}
          />
        </Box>
        {/* <Box marginTop={'1rem'}>
        <Typography variant={'subtitle1'}>Options</Typography>
        <OptInOption data={data} setData={setData} />
      </Box> */}
        <Box marginTop={'2rem'}>
          <Legend />
          <SitesTable
            role={role}
            data={data}
            setData={setData}
            selectedEprName={selectedEprName}
            setIsLoading={setIsLoading}
            cloneData={cloneData}
            setCloneData={setCloneData}
          />
        </Box>
      </Box>
    );
  }
};

export default SiteManagement;
